import Phaser from 'phaser';

export default class GameScene extends Phaser.Scene {
    constructor() {
        super('GameScene');
        this.config = {
            scrollSpeed: 2,
            birdSpeed: 300,
            targetSpawnInterval: 2000,
            maxWindSpeed: 5,
            windChangeSpeed: 0.1,
            maxLives: 3,
            respawnDelay: 1000,
            invincibilityDuration: 3000,
            eggRotationSpeed: 3,
            eggScale: 0.03,
            eggSpeed: 200,
            birdSize: 0.1,
            explosionScale: 0.5,
            explosionParticleCount: 20
        };
        this.score = 0;
        this.windSpeed = 0;
        this.targetWindSpeed = 0;
        this.eggs = [];
        this.settingsElements = [];
        this.targets = null;
        this.zombies = null;
        this.isGameOver = false;
        this.lives = this.config.maxLives;
        this.bird = null;
        this.eggGroup = null;
        this.settingsContainer = null;
        this.settingsButton = null;
        this.settingsIcon = null;
    }

    init(data) {
        this.birdType = data.birdType || this.config.defaultBirdType;
    }

    preload() {
        this.load.image('landscape', 'assets/landscape.png');
        this.load.image('egg', 'assets/egg.png');
        this.load.image('pelican', 'assets/pelican.png');
        this.load.image('falcon', 'assets/falcon.png');
        this.load.image('seagull', 'assets/seagull.png');
        this.load.image('target', 'assets/target.png');
        this.load.image('particle', 'assets/particle.png');
        this.load.spritesheet('pelican', 'assets/pelican_spritesheet.png', { frameWidth: 64, frameHeight: 64 });
        this.load.spritesheet('falcon', 'assets/falcon_spritesheet.png', { frameWidth: 64, frameHeight: 64 });
        this.load.spritesheet('seagull', 'assets/seagull_spritesheet.png', { frameWidth: 64, frameHeight: 64 });
        this.load.image('zombie', 'assets/zombie.png');
        this.load.image('settingsIcon', 'assets/settings-icon.png');
    }

    create() {
        // Create the scrolling background
        this.background = this.add.tileSprite(0, 0, this.cameras.main.width, this.cameras.main.height, 'landscape')
            .setOrigin(0, 0)
            .setScrollFactor(0);

        // Create a UI layer for static elements
        this.uiLayer = this.add.layer();

        // Add UI elements to the UI layer
        this.scoreText = this.uiLayer.add(this.add.text(20, 20, 'Score: 0', { fontSize: '24px', fill: '#fff' }));
        this.windSpeedText = this.uiLayer.add(this.add.text(this.cameras.main.width - 20, this.cameras.main.height - 40, 'Wind: 0 mph', { fontSize: '24px', fill: '#fff' }).setOrigin(1, 0));

        // Add lives counter to the upper right corner
        this.livesText = this.uiLayer.add(this.add.text(this.cameras.main.width - 20, 20, 'Lives: 3', { fontSize: '24px', fill: '#fff' }).setOrigin(1, 0));

        // Create the egg group
        this.eggGroup = this.physics.add.group();

        // Create the bird
        this.bird = this.physics.add.image(400, 300, this.birdType);
        this.bird.setCollideWorldBounds(true);

        // Set up input for dropping eggs
        this.input.keyboard.on('keydown-SPACE', this.dropEgg, this);

        this.cursors = this.input.keyboard.createCursorKeys();

        this.spaceBar = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);

        this.targets = this.physics.add.group();
        this.zombies = this.physics.add.group();

        this.time.addEvent({
            delay: this.config.targetSpawnInterval,
            callback: this.spawnZombieWithTarget,
            callbackScope: this,
            loop: true
        });

        this.physics.add.overlap(this.eggGroup, this.targets, this.hitTarget, null, this);
        this.physics.add.overlap(this.eggGroup, this.zombies, this.hitZombie, null, this);

        this.explosionEmitter = this.add.particles(0, 0, 'particle', {
            speed: { min: 100, max: 200 },
            scale: { start: 0.5, end: 0 },
            blendMode: 'ADD',
            lifespan: 800
        });

        // Initialize wind speed
        this.windSpeed = 0;
        this.targetWindSpeed = Phaser.Math.Between(0, this.config.maxWindSpeed);

        this.resetGame();

        // Remove any existing settings icon
        if (this.settingsIcon) {
            this.settingsIcon.destroy();
        }

        // Add settings icon to the bottom left
        this.settingsIcon = this.add.image(20, this.cameras.main.height - 20, 'settingsIcon')
            .setOrigin(0, 1)
            .setScale(0.5) // Adjust this value to resize the icon as needed
            .setInteractive({ useHandCursor: true })
            .setAlpha(0.7) // Makes the icon slightly transparent
            .on('pointerover', () => this.settingsIcon.setAlpha(1))
            .on('pointerout', () => this.settingsIcon.setAlpha(0.7))
            .on('pointerdown', () => this.openSettings());

        // Ensure the settings icon is on top of other game elements
        this.settingsIcon.setDepth(1000);
    }

    resetGame() {
        this.isGameOver = false;
        this.lives = this.config.maxLives;
        this.score = 0;
        this.windSpeed = 0;
        this.targetWindSpeed = 0;

        if (this.livesText) {
            this.livesText.setText(`Lives: ${this.lives}`);
        }
        if (this.scoreText) {
            this.scoreText.setText(`Score: ${this.score}`);
        }

        // Clear existing game objects
        this.eggGroup.clear(true, true);
        this.targets.clear(true, true);
        this.zombies.clear(true, true);

        // Remove existing bird if any
        if (this.bird) {
            this.bird.destroy();
        }

        // Spawn a new bird
        this.spawnBird();
    }

    spawnBird() {
        this.bird = this.physics.add.image(400, 300, this.birdType.toLowerCase());
        this.bird.setScale(this.config.birdSize);
        this.bird.setCollideWorldBounds(true);

        // Add collision between bird and zombies
        this.physics.add.overlap(this.bird, this.zombies, this.birdHitZombie, null, this);

        // Set initial alpha to 0.5 for visibility
        this.bird.setAlpha(0.5);

        // Create blinking effect
        this.tweens.add({
            targets: this.bird,
            alpha: { from: 0.5, to: 0.2 },
            duration: 200,
            yoyo: true,
            repeat: -1
        });

        // Set invincibility
        this.bird.isInvincible = true;

        // Timer to end invincibility
        this.time.delayedCall(this.config.invincibilityDuration, () => {
            if (this.bird && this.bird.active) {
                this.bird.isInvincible = false;
                this.tweens.killTweensOf(this.bird);
                this.bird.setAlpha(1);
            }
        });
    }

    update(time, delta) {
        if (this.isGameOver) return;

        // Scroll the background
        if (this.background) {
            this.background.tilePositionX += this.config.scrollSpeed;
        }

        this.handleBirdMovement();
        this.updateWindSpeed(delta);
        this.updateEggMovement();

        if (Phaser.Input.Keyboard.JustDown(this.spaceBar)) {
            this.dropEgg();
        }

        this.eggGroup.getChildren().forEach(egg => {
            if (egg.y > this.cameras.main.height) {
                egg.disableBody(true, true);
                this.eggGroup.remove(egg, true, true);
            }
        });

        this.targets.getChildren().forEach(target => {
            if (target.y < this.cameras.main.height / 2) {
                target.destroy();
            }
        });

        this.eggGroup.getChildren().forEach(egg => {
            console.log('Egg rotation:', egg.angle);
        });

        // Keep zombies and targets in bounds
        this.zombies.getChildren().forEach(zombie => {
            if (zombie.active && (zombie.x < 0 || zombie.x > this.cameras.main.width ||
                zombie.y < 0 || zombie.y > this.cameras.main.height)) {
                const target = this.targets.getChildren().find(t => t.zombie === zombie);
                if (target && target.active) {
                    this.setZombieMovement(zombie, target);
                }
            }
        });
    }

    handleBirdMovement() {
        if (!this.bird || !this.bird.body || this.isGameOver) return;

        const speed = this.config.birdSpeed;
        this.bird.body.setVelocity(0);

        if (this.cursors.left.isDown) {
            this.bird.body.setVelocityX(-speed);
        } else if (this.cursors.right.isDown) {
            this.bird.body.setVelocityX(speed);
        }

        if (this.cursors.up.isDown) {
            this.bird.body.setVelocityY(-speed);
        } else if (this.cursors.down.isDown) {
            this.bird.body.setVelocityY(speed);
        }
    }

    openSettings() {
        if (this.settingsContainer) return; // Prevent opening multiple popups

        const width = 400;
        const height = 450;
        const x = this.cameras.main.width / 2 - width / 2;
        const y = this.cameras.main.height / 2 - height / 2;

        const popup = this.add.rectangle(x, y, width, height, 0x4a4a4a, 0.9).setOrigin(0).setStrokeStyle(4, 0xffffff);
        const title = this.add.text(x + width / 2, y + 20, 'Settings', { fontSize: '32px', fill: '#ffffff', fontStyle: 'bold' }).setOrigin(0.5);

        const closeButton = this.add.text(x + width - 30, y + 10, 'X', { fontSize: '24px', fill: '#ffffff' })
            .setInteractive({ useHandCursor: true })
            .on('pointerdown', () => this.closeSettings());

        this.settingsContainer = this.add.container(0, 0, [popup, title, closeButton]);

        const sliderConfigs = [
            { key: 'scrollSpeed', label: 'Scroll Speed', min: 1, max: 5, step: 0.1 },
            { key: 'birdSpeed', label: 'Bird Speed', min: 100, max: 500, step: 10 },
            { key: 'eggScale', label: 'Egg Size', min: 0.01, max: 0.05, step: 0.001 },
            { key: 'eggSpeed', label: 'Egg Speed', min: 100, max: 400, step: 10 },
            { key: 'birdSize', label: 'Bird Size', min: 0.05, max: 0.3, step: 0.01 }
        ];

        sliderConfigs.forEach((config, index) => {
            const yPos = y + 80 + index * 70;
            this.createSlider(x + 30, yPos, width - 60, config, (value) => {
                this.config[config.key] = value;
                if (config.key === 'birdSize' && this.bird) {
                    this.bird.setScale(value);
                }
            });
        });
    }

    createSlider(x, y, width, config, onChange) {
        const label = this.add.text(x, y, config.label, { fontSize: '18px', fill: '#ffffff' });
        const valueText = this.add.text(x + width, y, this.config[config.key].toFixed(2), { fontSize: '18px', fill: '#ffffff' }).setOrigin(1, 0);
        
        const track = this.add.rectangle(x, y + 30, width, 8, 0xcccccc).setOrigin(0);
        const thumb = this.add.rectangle(x, y + 30, 20, 20, 0xffffff).setOrigin(0.5, 0.5);
        
        thumb.setInteractive({ draggable: true, useHandCursor: true });
        
        const updateSlider = (x) => {
            const newX = Phaser.Math.Clamp(x, track.x, track.x + track.width);
            const progress = (newX - track.x) / track.width;
            const value = Phaser.Math.RoundTo(config.min + (config.max - config.min) * progress, -2);
            thumb.x = newX;
            valueText.setText(value.toFixed(2));
            onChange(value);
        };

        thumb.on('drag', (pointer, dragX) => updateSlider(dragX));
        track.setInteractive({ useHandCursor: true }).on('pointerdown', (pointer) => {
            updateSlider(pointer.x);
        });

        // Set initial position
        const initialProgress = (this.config[config.key] - config.min) / (config.max - config.min);
        thumb.x = track.x + track.width * initialProgress;

        this.settingsContainer.add([label, valueText, track, thumb]);
    }

    closeSettings() {
        if (this.settingsContainer) {
            this.settingsContainer.destroy();
            this.settingsContainer = null;
        }
    }

    updateWindSpeed(delta) {
        // Gradually change wind speed towards target
        const windDiff = this.targetWindSpeed - this.windSpeed;
        if (Math.abs(windDiff) < 0.1) {
            // Set new target when close to current target
            this.targetWindSpeed = Phaser.Math.Between(0, this.config.maxWindSpeed);
        } else {
            // Move towards target
            this.windSpeed += windDiff * this.config.windChangeSpeed * (delta / 1000);
        }

        // Ensure wind speed stays between 0 and maxWindSpeed
        this.windSpeed = Phaser.Math.Clamp(this.windSpeed, 0, this.config.maxWindSpeed);

        // Update wind speed text
        this.windSpeedText.setText(`Wind: ${Math.round(this.windSpeed)} mph`);
    }

    dropEgg() {
        if (this.bird && this.eggGroup) {
            const egg = this.eggGroup.create(this.bird.x, this.bird.y, 'egg');
            egg.setScale(this.config.eggScale);
            
            // Set the egg's velocity (adjust these values as needed)
            egg.setVelocity(0, this.config.eggSpeed);
            
            // Add rotation to the egg
            egg.setAngularVelocity(this.config.eggRotationSpeed * 60);

            console.log('Egg dropped at:', egg.x, egg.y);
        }
    }

    updateEggMovement() {
        this.eggGroup.children.entries.forEach(egg => {
            // Ensure the egg keeps rotating
            egg.angle += this.config.eggRotationSpeed;

            // Remove eggs that are off-screen
            if (egg.y > this.cameras.main.height) {
                egg.destroy();
            }
        });
    }

    hitTarget(egg, target) {
        if (egg.active && target.active) {
            console.log('Egg hit target');
            
            // Stop egg rotation and movement
            egg.setAngularVelocity(0);
            egg.setVelocity(0);
            
            // Create explosion at the egg's position
            this.explosionEmitter.setPosition(egg.x, egg.y);
            this.explosionEmitter.setScale(this.config.explosionScale);
            this.explosionEmitter.explode(this.config.explosionParticleCount);

            // Add a flash effect
            const flash = this.add.circle(egg.x, egg.y, 50, 0xffffff, 1);
            this.tweens.add({
                targets: flash,
                alpha: 0,
                scale: 2,
                duration: 200,
                onComplete: () => flash.destroy()
            });

            // Immediately remove the egg, target, and zombie from their respective groups
            this.eggGroup.remove(egg, true, true);
            this.targets.remove(target, true, true);
            if (target.zombie) {
                this.zombies.remove(target.zombie, true, true);
            }

            // Fade out and destroy the egg, target, and zombie
            this.tweens.add({
                targets: [egg, target, target.zombie],
                alpha: 0,
                duration: 200,
                onComplete: () => {
                    egg.destroy();
                    target.destroy();
                    if (target.zombie) target.zombie.destroy();
                }
            });

            // Increase and update score
            this.score += 50;
            this.scoreText.setText(`Score: ${this.score}`);

            // Add a camera shake effect
            this.cameras.main.shake(200, 0.005);
        }
    }

    hitZombie(egg, zombie) {
        if (egg.active && zombie.active) {
            console.log('Egg hit zombie');
            
            // Stop egg rotation and movement
            egg.setAngularVelocity(0);
            egg.setVelocity(0);
            
            // Create a smaller explosion at the egg's position
            this.explosionEmitter.setPosition(egg.x, egg.y);
            this.explosionEmitter.setScale(this.config.explosionScale / 2);
            this.explosionEmitter.explode(this.config.explosionParticleCount / 2);

            // Immediately remove the egg from its group
            this.eggGroup.remove(egg, true, true);

            // Fade out and destroy the egg
            this.tweens.add({
                targets: egg,
                alpha: 0,
                duration: 200,
                onComplete: () => {
                    egg.destroy();
                }
            });

            // Optionally, you can add some effect to the zombie (e.g., flash red)
            this.tweens.add({
                targets: zombie,
                tint: 0xff0000,
                yoyo: true,
                duration: 100
            });

            // No score increase for hitting just the zombie
        }
    }

    spawnZombieWithTarget() {
        const x = Phaser.Math.Between(0, this.cameras.main.width);
        const y = this.cameras.main.height;
        
        const zombie = this.zombies.create(x, y, 'zombie');
        zombie.setScale(0.1); // Adjust scale as needed
        
        const target = this.targets.create(x, y - zombie.displayHeight / 2, 'target');
        target.setScale(0.1); // Adjust scale as needed
        
        // Link the target to its zombie
        target.zombie = zombie;
        
        this.setZombieMovement(zombie, target);
        this.setZombieBlink(zombie);
    }

    setZombieMovement(zombie, target) {
        // Check if zombie and target still exist
        if (!zombie || !zombie.active || !target || !target.active) {
            return; // Exit the function if either is not valid
        }

        const speed = 50; // Adjust as needed
        const direction = Phaser.Math.Between(0, 360);
        const vector = this.physics.velocityFromAngle(direction, speed);
        
        zombie.setVelocity(vector.x, vector.y);
        target.setVelocity(vector.x, vector.y);

        // Change direction every 2-5 seconds
        this.time.addEvent({
            delay: Phaser.Math.Between(2000, 5000),
            callback: () => {
                // Check again before calling setZombieMovement
                if (zombie && zombie.active && target && target.active) {
                    this.setZombieMovement(zombie, target);
                }
            },
            loop: false
        });
    }

    setZombieBlink(zombie) {
        const startBlink = () => {
            // Random delay before starting the blink
            const delay = Phaser.Math.Between(1000, 5000);
            this.time.delayedCall(delay, () => {
                if (zombie.active) {
                    this.tweens.add({
                        targets: zombie,
                        alpha: 0.3,
                        duration: 100,
                        yoyo: true,
                        repeat: 3,
                        onComplete: () => {
                            if (zombie.active) {
                                startBlink();
                            }
                        }
                    });
                }
            });
        };

        startBlink();
    }

    birdHitZombie(bird, zombie) {
        if (this.isGameOver || bird.isInvincible) return; // Prevent collision if invincible

        this.lives--;
        this.livesText.setText(`Lives: ${this.lives}`);

        // Camera shake effect
        this.cameras.main.shake(250, 0.01);

        // Remove the zombie and its target
        const target = this.targets.getChildren().find(t => t.zombie === zombie);
        if (target) {
            target.destroy();
        }
        zombie.destroy();

        // Make the bird fall and spin
        this.isGameOver = true;
        bird.body.enable = false;
        this.tweens.add({
            targets: bird,
            y: this.cameras.main.height + bird.height,
            angle: 720, // Two full rotations
            duration: 1500,
            ease: 'Cubic.easeIn',
            onComplete: () => {
                bird.destroy();
                if (this.lives > 0) {
                    this.respawnBird();
                } else {
                    this.showGameOverPopup();
                }
            }
        });
    }

    respawnBird() {
        // Spawn a new bird after a delay
        this.time.delayedCall(this.config.respawnDelay, () => {
            this.spawnBird();
            this.isGameOver = false;
        }, [], this);
    }

    showGameOverPopup() {
        const width = 300;
        const height = 200;
        const x = this.cameras.main.width / 2 - width / 2;
        const y = this.cameras.main.height / 2 - height / 2;

        const popup = this.add.rectangle(x, y, width, height, 0x000000, 0.8).setOrigin(0);

        const gameOverText = this.add.text(x + width / 2, y + 40, 'Game Over', { fontSize: '32px', fill: '#fff' }).setOrigin(0.5);

        const retryButton = this.add.text(x + width / 2, y + 100, 'Retry', { fontSize: '24px', fill: '#fff' })
            .setOrigin(0.5)
            .setInteractive({ useHandCursor: true })
            .on('pointerdown', () => this.retryGame());

        const quitButton = this.add.text(x + width / 2, y + 150, 'Quit', { fontSize: '24px', fill: '#fff' })
            .setOrigin(0.5)
            .setInteractive({ useHandCursor: true })
            .on('pointerdown', () => this.quitGame());

        // Add all elements to a container for easy management
        this.gameOverContainer = this.add.container(0, 0, [popup, gameOverText, retryButton, quitButton]);
    }

    retryGame() {
        if (this.gameOverContainer) {
            this.gameOverContainer.destroy();
        }
        this.resetGame();
    }

    quitGame() {
        if (this.settingsContainer) {
            this.settingsContainer.destroy();
            this.settingsContainer = null;
        }
        if (this.gameOverContainer) {
            this.gameOverContainer.destroy();
            this.gameOverContainer = null;
        }

        // Stop any ongoing game processes
        this.scene.stop();

        // Return to the MainMenu scene
        this.scene.start('MainMenu');
    }
}