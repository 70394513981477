import Phaser from 'phaser';

export default class ScoreScene extends Phaser.Scene {
    constructor() {
        super('ScoreScene');
    }

    create() {
        this.add.text(400, 300, 'Score Scene', { fontSize: '32px' }).setOrigin(0.5);
        // Add score display logic here
    }
}