import React, { useEffect, useRef } from 'react';
import Phaser from 'phaser';
import MainMenu from './scenes/MainMenu';
import GameScene from './scenes/GameScene';
import ScoreScene from './scenes/ScoreScene';

const config = {
  type: Phaser.AUTO,
  width: 800,
  height: 600,
  parent: 'phaser-game',
  scene: [MainMenu, GameScene, ScoreScene],
  physics: {
    default: 'arcade',
    arcade: { gravity: { y: 0 } }
  }
};

function App() {
  const gameRef = useRef(null);

  useEffect(() => {
    if (gameRef.current) return;
    gameRef.current = new Phaser.Game(config);

    return () => {
      if (gameRef.current) {
        gameRef.current.destroy(true);
        gameRef.current = null;
      }
    };
  }, []);

  return <div id="phaser-game" />;
}

export default App;
