import Phaser from 'phaser';

export default class MainMenu extends Phaser.Scene {
    constructor() {
        super('MainMenu');
    }

    preload() {
        this.load.image('falcon', 'assets/falcon.png');
        this.load.image('seagull', 'assets/seagull.png');
        this.load.image('pelican', 'assets/pelican.png');
    }

    create() {
        // Add game title
        this.add.text(400, 100, 'Bird Avenger', { 
            fontSize: '64px', 
            fill: '#ffffff',
            fontStyle: 'bold',
            stroke: '#000000',
            strokeThickness: 6
        }).setOrigin(0.5);

        // Add bird selection text
        this.add.text(400, 180, 'Select your bird:', { 
            fontSize: '32px', 
            fill: '#ffffff',
            stroke: '#000000',
            strokeThickness: 4
        }).setOrigin(0.5);

        // Create bird selection sprites
        const birdTypes = ['falcon', 'seagull', 'pelican'];
        const birdSpacing = 200;
        const startX = 400 - birdSpacing;
        const y = 350;

        birdTypes.forEach((birdType, index) => {
            const x = startX + index * birdSpacing;
            const bird = this.add.image(x, y, birdType)
                .setInteractive()
                .setScale(0.2) // Adjust this value to size your birds correctly
                .setOrigin(0.5);

            console.log(`${birdType} dimensions:`, bird.width, bird.height);

            // Add bird name below the sprite
            this.add.text(x, y + 100, birdType.charAt(0).toUpperCase() + birdType.slice(1), {
                fontSize: '24px',
                fill: '#ffffff',
                stroke: '#000000',
                strokeThickness: 3
            }).setOrigin(0.5);

            bird.on('pointerdown', () => this.startGame(birdType));
            bird.on('pointerover', () => bird.setScale(0.22));
            bird.on('pointerout', () => bird.setScale(0.2));
        });

        // Add instructions
        this.add.text(400, 550, 'Click on a bird to start', { 
            fontSize: '24px', 
            fill: '#ffffff',
            stroke: '#000000',
            strokeThickness: 3
        }).setOrigin(0.5);
    }

    startGame(birdType) {
        this.scene.start('GameScene', { birdType: birdType });
    }
}